import { Component, inject, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Apollo, gql } from 'apollo-angular';
import { ActivatedRoute } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { combineLatest, filter, map, of, switchMap, tap } from 'rxjs';
import { cloneDeep } from 'lodash-es';
import { TranslocoService } from '@jsverse/transloco';
import { ModelUtil, QueryUtil } from '~ngx-shared/utils';
import {
  AcademyCertificateModel,
  AcademyCertificateTypeEnum,
  AcademyCourseModel,
  AcademySubjectModel,
  CorePersonDataModel
} from '~ngx-shared/models';
import { FormlyService, UserService } from '~madrasa/services';
import { SchoolPeriodService } from '~madrasa/academy/services/school-period.service';
import { FormlyModule, FormlyUtil, FormSaveModel, FormSubmitModel } from '~ngx-shared/formly';
import { BasePageComponent, LoadingService } from '~ngx-shared/layout';
import { AuthorizationService, Role } from '~ngx-shared/authentication';

@UntilDestroy()
@Component({
  selector: 'app-create-update-certificate-form-page',
  standalone: true,
  imports: [BasePageComponent, FormlyModule],
  templateUrl: './create-update-certificate-form-page.component.html',
  styleUrl: './create-update-certificate-form-page.component.scss'
})
export class CreateUpdateCertificateFormPageComponent implements OnInit {
  readonly schoolPeriodService = inject(SchoolPeriodService);
  readonly apollo = inject(Apollo);
  readonly activatedRoute = inject(ActivatedRoute);
  readonly loadingService = inject(LoadingService);
  readonly authorizationService = inject(AuthorizationService);
  readonly formlyService = inject(FormlyService);
  readonly translocoService = inject(TranslocoService);
  readonly userService = inject(UserService);

  form = new FormGroup({});
  readOnlyModel: AcademyCertificateModel = {};
  model: any = {};
  options: FormlyFormOptions = {
    formState: {
      transloco: 'madrasa.forms.create_update_certificate'
    }
  };
  fields: FormlyFieldConfig[];
  submit: FormSubmitModel;

  ngOnInit(): void {
    this.loadingService.startLoading();

    combineLatest([
      this.activatedRoute.data.pipe(
        untilDestroyed(this),
        switchMap(data => {
          const result = !!data?.['crud'] && !data['crud']['is_new'] && !!data['crud']['id'];
          if (result) {
            return this.apollo
              .query<{ result: AcademyCertificateModel }>({
                query: gql`
                  query ReadAcademyCertificateId($id: bigint!) {
                    result: academy_certificate_by_pk(id: $id) {
                      id
                      person_id
                      school_id
                      school_period_id
                      certificate_type
                      options
                      remark
                      date_of_certificate

                      school {
                        organisation_id
                      }

                      course {
                        id
                        course_type_id
                        name
                        course_name {
                          name
                        }
                        certificate_templates {
                          id
                          name
                          options
                        }
                      }

                      current_person_data {
                        academic_degree_prefix
                        academic_degree_suffix
                        first_name
                        last_name
                        person_id
                        date_of_birth
                      }

                      certificate_subjects(
                        order_by: { subject: { name: asc } }
                        ${
                          this.authorizationService.can(Role.TEACHER)
                            ? `
                            where: {
                              _or: [
                                {
                                  units: {
                                    course: {
                                      certificates: { id: { _eq: $id } }
                                      course_teachers: { person_id: { _eq: ${this.userService.user()?.person_id} } }
                                    }
                                  }
                                }
                                {
                                  units: {
                                    course: { certificates: { id: { _eq: $id } } }
                                    unit_teachers: { person_id: { _eq: ${this.userService.user()?.person_id} } }
                                  }
                                }
                              ]
                            }`
                            : ''
                        }
                      ) {
                        id
                        subject_id
                        subject {
                          name
                        }
                        grade
                        is_not_assessed
                        remark
                      }
                    }
                  }
                `,
                variables: {
                  id: data['crud']['id']
                }
              })
              .pipe(map(queryResult => queryResult.data?.result));
          } else {
            return of<AcademyCertificateModel>({});
          }
        })
      ),
      this.schoolPeriodService.currentPeriod$
    ]).subscribe(result => {
      if (result[0] && result[1]) {
        const schoolPeriod = result[1];

        if (result[0]?.id) {
          this.readOnlyModel = result[0];
          let model = cloneDeep(result[0]);

          if (model) {
            const options: any[] = [];
            model?.course?.certificate_templates?.forEach((certificateTemplate: any) => {
              certificateTemplate?.options?.forEach((item: any) =>
                options.push({
                  name: item.name,
                  is_title: item.is_title,
                  grade: model?.options?.find((option: any) => option.name === item.name)?.grade
                })
              );
            });
            this.model = {
              ...model,
              date_of_certificate: FormlyUtil.fromIsoDateString(model.date_of_certificate, false),
              organisation_id: model.school?.organisation_id,
              person_id: {
                label: ModelUtil.getFullName(model.current_person_data, this.translocoService),
                value: {
                  label: ModelUtil.getFullName(model.current_person_data, this.translocoService),
                  value: model.current_person_data
                }
              },
              certificate_subjects: [
                ...(model.certificate_subjects?.map(item => ({
                  ...item,
                  name: item.subject?.name
                })) || []),
                ...options
              ]
            };
          }
        } else {
          this.model = {
            school_period_id: schoolPeriod.id,
            organisation_id:
              ModelUtil.getValidId(
                this.activatedRoute.snapshot.queryParamMap.get('organisation_id')
              ) || undefined,
            school_id:
              ModelUtil.getValidId(this.activatedRoute.snapshot.queryParamMap.get('school_id')) ||
              undefined,
            course_id:
              ModelUtil.getValidId(this.activatedRoute.snapshot.queryParamMap.get('course_id')) ||
              undefined
          };
        }

        this.fields = [
          {
            key: 'id'
          },
          this.formlyService.createOrganisationFieldConfig({
            required: true,
            readonly: this.model?.id
          }),
          this.formlyService.createSchoolFieldConfig({ required: true, readonly: this.model?.id }),
          FormlyUtil.createRow([
            FormlyUtil.createSelectField('school_period_id', {
              props: {
                label: 'school_period',
                required: true,
                readonly: true,
                options: this.schoolPeriodService.getPeriods().pipe(
                  map(
                    schoolPeriods =>
                      schoolPeriods?.map(schoolPeriod => ({
                        label: schoolPeriod.name,
                        value: schoolPeriod.id
                      })) || []
                  )
                )
              }
            }),
            FormlyUtil.createSelectField('certificate_type', {
              props: {
                required: true,
                readonly: this.model?.id,
                options: Object.values(AcademyCertificateTypeEnum).map(value => ({
                  label: 'certificate_type.' + value,
                  value: value
                }))
              }
            })
          ]),
          FormlyUtil.createRow([
            {
              key: 'course_id'
            },
            FormlyUtil.createSelectField('course', {
              props: {
                required: true,
                readonly: this.model?.id,
                options: []
              },
              hooks: {
                onInit: (field: FormlyFieldConfig) => {
                  const createRequest = (
                    schoolPeriodId?: number,
                    schoolId?: number,
                    courseId?: number
                  ) => {
                    return this.apollo
                      .query<{
                        result: AcademyCourseModel[];
                      }>({
                        query: gql`
                          query ReadAcademyCourses($where: academy_course_bool_exp = {}) {
                            result: academy_course(order_by: { name: asc }, where: $where) {
                              id
                              course_type_id
                              name
                              course_name {
                                name
                              }
                              certificate_templates {
                                id
                                name
                                options
                              }
                            }
                          }
                        `,
                        variables: {
                          where: {
                            school_id: { _eq: schoolId },
                            school_period_id: { _eq: schoolPeriodId }
                          }
                        }
                      })
                      .pipe(
                        map(queryResult =>
                          queryResult.data.result.map(item => ({
                            label: item.name || item.course_name?.name,
                            value: item
                          }))
                        ),
                        tap(options => {
                          if (courseId) {
                            field.formControl?.setValue(
                              options.find(option => option.value.id === courseId)?.value
                            );
                          }
                        })
                      );
                  };

                  if (field.props && field.model.school_id && field.model.school_period_id) {
                    field.props.options = createRequest(
                      field.model.school_period_id,
                      field.model.school_id,
                      field.model.course_id
                    );
                  }
                  return field.options?.fieldChanges?.pipe(
                    filter(
                      event =>
                        event.type === 'valueChanges' &&
                        (event.field.key === 'school_id' || event.field.key === 'school_period_id')
                    ),
                    tap(event => {
                      field.formControl?.setValue(undefined);

                      if (field.props && field.model.school_id && field.model.school_period_id) {
                        field.props.options = createRequest(
                          field.model.school_period_id,
                          field.model.school_id
                        );
                      }
                    })
                  );
                }
              }
            }),
            FormlyUtil.createDatePickerField('date_of_certificate', {
              props: {
                required: false
              }
            })
          ]),
          this.formlyService.createPersonFieldConfig({
            required: true,
            readonly: this.model?.id,
            forceSelection: true,
            conditions: field => {
              return [
                {
                  latest_course_students_active: {
                    course_id: {
                      _eq: field.model?.course?.id
                    }
                  }
                }
              ];
            }
          }),
          FormlyUtil.createRow([
            FormlyUtil.createTable(
              {
                key: 'certificate_subjects',
                props: {
                  label: 'subjects',
                  showIndex: true,
                  canAdd: () => false,
                  canRemove: () => false,
                  columns: [
                    {
                      header: 'subject',
                      key: 'name'
                    },
                    {
                      header: 'is_not_assessed',
                      key: 'is_not_assessed'
                    },
                    {
                      header: 'grade',
                      key: 'grade'
                    },
                    {
                      header: 'remark',
                      key: 'remark'
                    }
                  ]
                },
                hooks: {
                  onInit: (field: FormlyFieldConfig) => {
                    return field.options?.fieldChanges?.pipe(
                      filter(
                        event =>
                          event.type === 'valueChanges' &&
                          (event.field.key === 'course' ||
                            event.field.key === 'school_id' ||
                            event.field.key === 'person_id')
                      ),
                      tap(event => {
                        if (
                          field.parent?.model?.course?.id &&
                          field.props?.['course_id'] != field.parent?.model?.course?.id
                        ) {
                          let variables: any = {
                            where: {
                              units: {
                                course_id: { _eq: field.parent?.model?.course?.id }
                              }
                            }
                          };
                          this.apollo
                            .query<{
                              result: AcademySubjectModel[];
                            }>({
                              query: gql`
                                query ReadAcademySubjects($where: academy_subject_bool_exp = {}) {
                                  result: academy_subject(order_by: { name: asc }, where: $where) {
                                    id
                                    name
                                  }
                                }
                              `,
                              variables
                            })
                            .subscribe(queryResult => {
                              if (field.props) {
                                field.props['course_id'] = field.parent?.model?.course?.id;
                              }
                              const certificate_subjects: any[] = [];
                              queryResult.data.result?.forEach(item =>
                                certificate_subjects.push({
                                  subject_id: item.id,
                                  name: item.name
                                })
                              );
                              field.parent?.model?.course?.certificate_templates?.forEach(
                                (certificateTemplate: any) => {
                                  certificateTemplate?.options?.forEach((item: any) =>
                                    certificate_subjects.push({
                                      name: item.name,
                                      is_title: item.is_title
                                    })
                                  );
                                }
                              );

                              this.model = {
                                ...this.model,
                                certificate_subjects
                              };
                            });
                        }
                      })
                    );
                  }
                }
              },
              [
                {
                  key: 'id'
                },
                {
                  key: 'subject_id'
                },
                {
                  key: 'is_title'
                },
                FormlyUtil.createLabelField('name', {
                  className: 'min-w-32',
                  props: {
                    label: 'name',
                    disableMargin: true,
                    hideLabel: true
                  },
                  expressions: {
                    'props.labelClass': field => {
                      return field.model?.is_title ? 'font-bold' : 'font-normal';
                    }
                  }
                }),
                FormlyUtil.createCheckboxField('is_not_assessed', {
                  className: 'min-w-32',
                  props: {
                    disableMargin: true,
                    hideLabel: true,
                    containerClass: 'flex justify-center'
                  },
                  expressions: {
                    hide: field => {
                      return field.model?.is_title;
                    }
                  }
                }),
                FormlyUtil.createNumberField('grade', {
                  className: 'min-w-32 w-full',
                  props: {
                    disableMargin: true,
                    hideLabel: true,
                    required: true,
                    min: 1,
                    max: 5,
                    showClear: true
                  },
                  expressions: {
                    hide: field => {
                      return field.model?.is_title || field.model?.is_not_assessed;
                    }
                  }
                }),
                FormlyUtil.createTextField('remark', {
                  props: {
                    hideLabel: true,
                    disableMargin: true
                  },
                  expressions: {
                    hide: field => {
                      return field.model?.is_title;
                    }
                  }
                })
              ]
            )
          ]),
          FormlyUtil.createRow([FormlyUtil.createTextAreaField('remark')])
        ];

        this.loadingService.stopLoading();
      }
    });

    this.submit = (formSaveModel: FormSaveModel) => {
      const input = {
        ...formSaveModel.input,
        date_of_certificate: FormlyUtil.toIsoDateString(formSaveModel.input.date_of_certificate),
        person_id: formSaveModel.input.person_id?.value?.value?.person_id,
        course_id: formSaveModel.input.course?.id,
        certificate_subjects: {
          data: formSaveModel.input.certificate_subjects
            ?.filter((item: any) => item.subject_id)
            ?.map((item: any) => ({
              subject_id: item.subject_id,
              grade: item.is_not_assessed ? null : String(item.grade),
              is_not_assessed: item.is_not_assessed,
              remark: item.remark
            }))
        },
        options:
          formSaveModel.input.certificate_subjects?.filter((item: any) => !item.subject_id) || null
      };

      ModelUtil.deleteKey(input);
      ModelUtil.deleteKey(input, 'course');
      ModelUtil.deleteKey(input, 'organisation_id');

      const params: string[] = ['$input: academy_certificate_insert_input!'];
      const queries: string[] = [
        `
         result: insert_academy_certificate_one(object: $input) {
            __typename
         }
        `
      ];
      let variables: any = { input };

      if (this.model?.id) {
        ModelUtil.deleteKey(input);
        ModelUtil.deleteKey(input, 'certificate_subjects');
        ModelUtil.deleteKey(input, 'course_id');
        ModelUtil.deleteKey(input, 'school_id');
        ModelUtil.deleteKey(input, 'school_period_id');
        ModelUtil.deleteKey(input, 'certificate_type');
        ModelUtil.deleteKey(input, 'person_id');

        // Clear params
        params.length = 0;
        queries.length = 0;

        params.push('$id: bigint!', '$input: academy_certificate_set_input!');

        queries.push(`
          result: update_academy_certificate_by_pk(
            pk_columns: { id: $id }
            _set: $input
          ) {
            __typename
          }
        `);

        variables = { id: this.model.id, input };

        const newCertificateSubjects = QueryUtil.comparer({
          itemsA: formSaveModel.input.certificate_subjects?.filter((item: any) => item.subject_id),
          itemsB: this.readOnlyModel.certificate_subjects,
          compareFunc: (itemA: any, itemB: any) => itemA?.id === itemB?.id,
          resultMapFunc: item => item
        })?.map((item: any) => ({
          subject_id: item.id,
          grade: item.is_not_assessed ? null : String(item.grade),
          is_not_assessed: item.is_not_assessed,
          remark: item.remark,
          certificate_id: this.model.id
        }));

        if (newCertificateSubjects?.length) {
          params.push('$newCertificateSubjects: [academy_certificate_subject_insert_input!] = []');
          queries.push(`
            insert_academy_certificate_subject(objects: $newCertificateSubjects) {
              __typename
            }
          `);
          variables = {
            ...variables,
            newCertificateSubjects
          };
        }

        const updateCertificateSubjects: any[] =
          // Deleted ones first
          QueryUtil.comparer({
            itemsA: this.readOnlyModel.certificate_subjects,
            itemsB: formSaveModel.input.certificate_subjects?.filter(
              (item: any) => item.subject_id
            ),
            compareFunc: (itemA: any, itemB: any) => !!itemB?.id && itemA.id === itemB.id,
            resultMapFunc: item => ({
              where: {
                id: { _eq: item.id }
              },
              _set: { deleted_at: 'now()' }
            })
          }) || [];

        updateCertificateSubjects.push(
          ...(this.readOnlyModel.certificate_subjects
            ?.map((item: any) => {
              const subject = formSaveModel.input.certificate_subjects
                ?.filter((item: any) => item.subject_id)
                ?.find((subject: any) => subject.id === item.id);
              if (
                subject &&
                (subject.grade !== item.grade ||
                  subject.remark !== item.remark ||
                  subject.is_not_assessed !== item.is_not_assessed)
              ) {
                return {
                  where: {
                    id: { _eq: item.id }
                  },
                  _set: {
                    grade: subject.is_not_assessed ? null : String(subject.grade),
                    is_not_assessed: subject.is_not_assessed,
                    remark: subject.remark
                  }
                };
              }
              return undefined;
            })
            .filter((item: any) => !!item) || [])
        );

        if (updateCertificateSubjects?.length) {
          params.push('$updateCertificateSubjects: [academy_certificate_subject_updates!] = []');
          queries.push(`
            update_academy_certificate_subject_many(updates: $updateCertificateSubjects) {
              __typename
            }
          `);
          variables = {
            ...variables,
            updateCertificateSubjects
          };
        }
      }

      return this.apollo.mutate({
        mutation: gql`
            mutation CreateUpdateAcademyCertificate(
              ${params.join('\n')}
            ) {
             ${queries.join('\n')}
            }
          `,
        variables
      });
    };

    this.schoolPeriodService.getPeriods().subscribe();
  }

  getFullName(personDataModel: CorePersonDataModel | undefined) {
    return ModelUtil.getFullName(personDataModel, this.translocoService);
  }
}
