import { Component, inject, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Apollo, gql } from 'apollo-angular';
import { ActivatedRoute } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { map, of, switchMap } from 'rxjs';
import { cloneDeep } from 'lodash-es';
import { AccountingAccountGroupModel, AccountingAccountModel } from '~ngx-shared/models';
import { AccountingPeriodService } from '~madrasa/accounting/services/accounting-period.service';
import { FormlyModule, FormlyUtil, FormSaveModel, FormSubmitModel } from '~ngx-shared/formly';
import { BasePageComponent, LoadingService } from '~ngx-shared/layout';
import { ModelUtil } from '~ngx-shared/utils';
import { FormlyService } from '~madrasa/services';

@UntilDestroy()
@Component({
  selector: 'app-create-update-account-form-page',
  standalone: true,
  imports: [BasePageComponent, FormlyModule],
  templateUrl: './create-update-account-form-page.component.html',
  styleUrl: './create-update-account-form-page.component.scss'
})
export class CreateUpdateAccountFormPageComponent implements OnInit {
  readonly apollo = inject(Apollo);
  readonly formlyService = inject(FormlyService);
  readonly activatedRoute = inject(ActivatedRoute);
  readonly loadingService = inject(LoadingService);
  readonly accountingPeriodService = inject(AccountingPeriodService);

  form = new FormGroup({});
  model: AccountingAccountModel = {};
  options: FormlyFormOptions = {
    formState: {
      transloco: 'madrasa.forms.create_update_account'
    }
  };
  fields: FormlyFieldConfig[];
  submit: FormSubmitModel;

  ngOnInit(): void {
    this.loadingService.startLoading();

    this.activatedRoute.data
      .pipe(
        untilDestroyed(this),
        switchMap(data => {
          const result = !!data?.['crud'] && !data['crud']['is_new'] && !!data['crud']['id'];
          if (result) {
            return this.apollo
              .query<{ result: AccountingAccountModel }>({
                query: gql`
                  query ReadAccountingAccountId($id: bigint!) {
                    result: accounting_account_by_pk(id: $id) {
                      id
                      account_group_id
                      name
                      number
                      position
                      opening_amount
                      show_always
                      school_id
                      school {
                        organisation_id
                      }
                    }
                  }
                `,
                variables: {
                  id: data['crud']['id']
                }
              })
              .pipe(map(queryResult => queryResult.data?.result));
          } else {
            return of(undefined);
          }
        })
      )
      .subscribe(result => {
        if (result) {
          let model = cloneDeep(result);

          if (model) {
            this.model = {
              ...model,
              opening_amount: model.opening_amount ? model.opening_amount / 100 : undefined,
              organisation_id: model.school?.organisation_id
            };
          }
        }

        this.fields = [
          {
            key: 'id'
          },
          FormlyUtil.createRow([
            FormlyUtil.createSelectField('account_group_id', {
              props: {
                label: 'account_group',
                required: true,
                showClear: true,
                canFilter: true,
                options: this.apollo
                  .query<{
                    result: AccountingAccountGroupModel[];
                  }>({
                    query: gql`
                      query ReadAccountingAccountGroups($where: accounting_account_group_bool_exp) {
                        result: accounting_account_group(
                          where: $where
                          order_by: [{ number: asc }, { name: asc }]
                        ) {
                          id
                          full_name
                        }
                      }
                    `,
                    variables: {
                      where: {
                        accounting_period_id: {
                          _eq: this.accountingPeriodService.currentPeriod()?.id
                        }
                      }
                    }
                  })
                  .pipe(
                    map(queryResult =>
                      queryResult.data.result.map(item => ({
                        label: item.full_name,
                        value: item.id
                      }))
                    )
                  )
              }
            })
          ]),
          FormlyUtil.createRow([
            FormlyUtil.createNumberField('number', {
              props: {
                required: true,
                min: 0
              }
            }),
            FormlyUtil.createTextField('name', {
              props: {
                required: true
              }
            })
          ]),
          FormlyUtil.createRow([
            FormlyUtil.createNumberField('opening_amount', {
              props: {
                maxFractionDigits: 2,
                iconPre: 'pi pi-euro',
                min: 0
              }
            })
          ]),
          FormlyUtil.createRow([
            FormlyUtil.createCheckboxField('show_always', {
              props: {
                label: 'show_always',
                required: false
              }
            })
          ]),
          this.formlyService.createOrganisationFieldConfig({ required: false }),
          this.formlyService.createSchoolFieldConfig({ required: false })
        ];

        this.loadingService.stopLoading();
      });

    this.submit = (formSaveModel: FormSaveModel) => {
      const input = {
        ...formSaveModel.input,
        opening_amount: formSaveModel.input.opening_amount
          ? Math.round(formSaveModel.input.opening_amount * 100)
          : 0,
        organisation_id: formSaveModel.input.organisation_id || null,
        school_id: formSaveModel.input.school_id || null
      };

      ModelUtil.deleteKey(input, 'organisation_id');

      let options: any = {
        mutation: gql`
          mutation CreateAccountingAccount($input: accounting_account_insert_input!) {
            result: insert_accounting_account_one(object: $input) {
              __typename
            }
          }
        `,
        variables: {
          input
        }
      };

      if (this.model?.id) {
        ModelUtil.deleteKey(input);
        ModelUtil.deleteKey(input, 'account_group_id');

        options = {
          mutation: gql`
            mutation UpdateAccountingAccount($id: bigint!, $input: accounting_account_set_input!) {
              result: update_accounting_account_by_pk(pk_columns: { id: $id }, _set: $input) {
                __typename
              }
            }
          `,
          variables: {
            id: this.model.id,
            input
          }
        };
      }

      return this.apollo.mutate(options);
    };

    this.accountingPeriodService.getPeriods().subscribe();
  }
}
