import { Component, inject, OnInit, signal } from '@angular/core';
import { TranslocoDirective, TranslocoService } from '@jsverse/transloco';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { TableLazyLoadEvent, TableModule } from 'primeng/table';
import { NgxFilesizeModule } from 'ngx-filesize';
import { TooltipModule } from 'primeng/tooltip';
import { toSignal } from '@angular/core/rxjs-interop';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map, switchMap, tap } from 'rxjs';
import { Apollo, gql } from 'apollo-angular';
import { CurrencyPipe, NgClass } from '@angular/common';
import { ButtonDirective } from 'primeng/button';
import { DialogService } from 'primeng/dynamicdialog';
import { FormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import {
  BankDataImportActionType,
  BankDataImportItem,
  BankingDataImportService
} from '~madrasa/accounting/services/bank-data-import.service';
import {
  AccountingAccountModel,
  AccountingCashBookEntryTypeEnum,
  AccountingEntryRowTypeEnum
} from '~ngx-shared/models';
import { AccountingPeriodService } from '~madrasa/accounting/services/accounting-period.service';
import { DatePipe } from '~ngx-shared/pipes';
import {
  BasePageComponent,
  ConfirmationService,
  LoadingService,
  ToastService
} from '~ngx-shared/layout';
import { DialogUtil } from '~ngx-shared/utils/dialog.util';
import { CreateUpdateBankDataImportActionFormDialogComponent } from '~madrasa/accounting/components/create-update-bank-data-import-action-form-dialog/create-update-bank-data-import-action-form-dialog.component';
import { FormlyUtil } from '~ngx-shared/formly';
import { PersonUtil } from '~madrasa/core/utils/person.util';
import { ModelUtil } from '~ngx-shared/utils';

@UntilDestroy()
@Component({
  selector: 'app-bank-data-import-page',
  standalone: true,
  imports: [
    BasePageComponent,
    TranslocoDirective,
    NgxPermissionsModule,
    TableModule,
    NgxFilesizeModule,
    TooltipModule,
    DatePipe,
    CurrencyPipe,
    NgClass,
    ButtonDirective,
    FormsModule,
    InputTextModule,
    RouterLink
  ],
  templateUrl: './bank-data-import-page.component.html',
  styleUrl: './bank-data-import-page.component.scss',
  providers: [CurrencyPipe]
})
export class BankDataImportPageComponent implements OnInit {
  readonly accountingPeriodService = inject(AccountingPeriodService);
  readonly bankDataImportService = inject(BankingDataImportService);
  readonly activatedRoute = inject(ActivatedRoute);
  readonly loadingService = inject(LoadingService);
  readonly apollo = inject(Apollo);
  readonly dialogService = inject(DialogService);
  readonly confirmationService = inject(ConfirmationService);
  readonly translocoService = inject(TranslocoService);
  readonly currencyPipe = inject(CurrencyPipe);
  readonly toastService = inject(ToastService);

  readonly importData = toSignal(
    this.activatedRoute.paramMap.pipe(
      untilDestroyed(this),
      map(paramMap => {
        this.loadingService.startLoading();

        let bankDataImport = this.bankDataImportService.getImport(
          String(paramMap.get('bank-data-id'))
        );
        if (bankDataImport) {
          if (!bankDataImport.is_analyzed && bankDataImport.file_content) {
            bankDataImport = this.bankDataImportService.analyze(bankDataImport);
            this.bankDataImportService.saveImport(bankDataImport);
          }
        }
        return bankDataImport;
      }),
      tap(() => this.loadingService.stopLoading())
    )
  );

  readonly accounts = toSignal(
    this.accountingPeriodService.currentPeriod$.pipe(
      switchMap(period =>
        this.apollo
          .query<{
            result: AccountingAccountModel[];
          }>({
            query: gql`
              query ReadAccountingAccounts($where: accounting_account_bool_exp = {}) {
                result: accounting_account(
                  where: $where
                  order_by: [{ account_group: { number: asc } }, { number: asc }, { name: asc }]
                ) {
                  id
                  full_name
                  full_number
                  school_id
                  number
                  account_group {
                    number
                    category
                  }
                }
              }
            `,
            variables: {
              where: {
                account_group: {
                  accounting_period_id: {
                    _eq: period?.id
                  }
                }
              }
            }
          })
          .pipe(map(queryResult => queryResult.data?.result))
      )
    )
  );

  readonly isBusy = signal<boolean>(false);
  readonly isLoading = signal<boolean>(false);
  readonly selections = signal<BankDataImportItem[]>([]);
  readonly slicedImportDataItems = signal<BankDataImportItem[]>([]);
  protected readonly AccountingCashBookEntryTypeEnum = AccountingCashBookEntryTypeEnum;
  private readonly _personIdRegExp = new RegExp('PID[:\\s]*?(?<pid>\\d+)', 'gmi');
  private readonly _studentBalanceRegExp = new RegExp(
    'STB[:\\s]*?(?<month>\\d{2})/(?<year>\\d{2})',
    'gmi'
  );
  private readonly _accountIdRegExp = new RegExp('ACID[:s]*?(?<acid>\\d+)', 'gmi');
  private readonly _accountNrRegExp = new RegExp('ACNR[:s]*?(?<acnr>\\d+)', 'gmi');

  ngOnInit() {
    this.accountingPeriodService.getPeriods().subscribe();
  }

  getRow(data: any): BankDataImportItem {
    return data;
  }

  getRowAction(data: any): BankDataImportActionType | undefined {
    return this.getRow(data).action;
  }

  selectionChange(data: BankDataImportItem[]) {
    const selections = data.filter(item => !!item.action && !item.is_booked);
    this.selections.set(selections);
  }

  editAction(data: any, rowIndex: any) {
    const item = this.getRow(data);
    this.dialogService
      .open(CreateUpdateBankDataImportActionFormDialogComponent, {
        ...DialogUtil.BASE_DIALOG_CONFIG,
        header:
          this.translocoService.translate('number') +
          ' ' +
          (rowIndex + 1) +
          ' - ' +
          this.currencyPipe.transform(item.amount || 0),
        data: {
          accounts: this.accounts(),
          importData: this.importData(),
          item
        }
      })
      .onClose.pipe(untilDestroyed(this))
      .subscribe(result => {
        if (result && result.output) {
          item.action = result.output;
          this.bankDataImportService.saveImport(this.importData());
        }
      });
  }

  deleteAction(data: any, rowIndex: any) {
    const item = this.getRow(data);

    this.confirmationService.confirmDelete(
      this.translocoService.translate('actions') +
        ' - ' +
        this.translocoService.translate('number') +
        ' ' +
        (rowIndex + 1) +
        ' - ' +
        this.currencyPipe.transform(item.amount || 0),
      () => {
        item.action = undefined;
        this.bankDataImportService.saveImport(this.importData());
      }
    );
  }

  getAccountFullName(account_id: number | undefined) {
    return this.accounts()?.find(account => account.id === account_id)?.full_name;
  }

  loadImportData(event: TableLazyLoadEvent) {
    this.isLoading.set(true);
    const importData = this.importData();
    if (importData?.is_analyzed) {
      const slicedBankDataItems =
        importData?.items?.slice(event.first, (event.first || 0) + (event.rows || 0)) || [];

      const metaItems: {
        personRegExpResult: RegExpExecArray[];
        studentBalanceRegExpResult: RegExpExecArray[];
        accountIdRegExpResult: RegExpExecArray[];
        accountNrRegExpResult: RegExpExecArray[];
      }[] = [];

      // Step 1: Extract id's from the entry
      slicedBankDataItems.forEach(item => {
        metaItems.push({
          personRegExpResult: this._parseString(
            this._personIdRegExp,
            item.reason_for_payment,
            item.payment_reference
          ),
          studentBalanceRegExpResult: this._parseString(
            this._studentBalanceRegExp,
            item.reason_for_payment,
            item.payment_reference
          ),
          accountIdRegExpResult: this._parseString(
            this._accountIdRegExp,
            item.reason_for_payment,
            item.payment_reference
          ),
          accountNrRegExpResult: this._parseString(
            this._accountNrRegExp,
            item.reason_for_payment,
            item.payment_reference
          )
        });
      });

      if (metaItems.some(metaItem => metaItem.personRegExpResult.length)) {
        // Step 2: Fetch the id's from the database if personId is found
        PersonUtil.queryCurrentPersonData(this.apollo, {
          variables: {
            where: {
              person_id: {
                _in: metaItems.flatMap(metaItem =>
                  metaItem.personRegExpResult.map(result => result.groups?.['pid'])
                )
              }
            }
          }
        }).subscribe(queryResult => {
          // Step 3: Assign the fetched id's to the entry

          slicedBankDataItems.forEach((item, index) => {
            const metaItem = metaItems[index];
            if (!item.is_booked) {
              this._setAccount(metaItem, item);

              const personData = queryResult.data.result?.find(
                person => person.person_id == metaItem.personRegExpResult?.[0]?.groups?.['pid']
              );
              if (personData) {
                const full_name = ModelUtil.getFullName(personData, this.translocoService);

                item.action = {
                  ...item.action,
                  student_balances: metaItem.studentBalanceRegExpResult.map(result => ({
                    person_id: {
                      label: full_name,
                      value: {
                        label: full_name,
                        value: {
                          ...personData,
                          full_name
                        }
                      },
                      disabled: false
                    },
                    balances: [
                      {
                        amount:
                          (item.amount || 0) *
                          (item.type === AccountingCashBookEntryTypeEnum.OUTGOING ? -1 : 1),
                        month_of_balance: new Date(
                          2000 + parseInt(result.groups?.['year'] || '25'),
                          parseInt(result.groups?.['month'] || '01') - 1
                        )
                      }
                    ]
                  }))
                };
              }
            }
          });

          this.slicedImportDataItems.set(slicedBankDataItems);
          this.isLoading.set(false);
        });
      } else if (
        metaItems.some(
          metaItem => metaItem.accountIdRegExpResult.length || metaItem.accountNrRegExpResult.length
        )
      ) {
        const timeout = setTimeout(() => {
          // Step 3: Assign the fetched id's to the entry
          slicedBankDataItems.forEach((item, index) => {
            const metaItem = metaItems[index];
            if (!item.is_booked) {
              this._setAccount(metaItem, item);
            }
          });
          clearTimeout(timeout);
          this.slicedImportDataItems.set(slicedBankDataItems);
          this.isLoading.set(false);
        }, 300);
      } else {
        this.slicedImportDataItems.set(slicedBankDataItems);
        this.isLoading.set(false);
      }
    }
  }

  book(event: MouseEvent) {
    this.confirmationService.confirmPopup(event, () => {
      this.isBusy.set(true);

      const entries: any[] = [];

      const selectedEntries: BankDataImportItem[] = [];

      this.selections()?.forEach(item => {
        let sum = 0;
        const entryRows: any[] = [];
        if (item.action?.rows?.length) {
          entryRows.push(
            ...item.action?.rows?.map(fItem => {
              sum += fItem.amount || 0;
              return {
                amount: fItem.amount || 0,
                type:
                  item?.type === AccountingCashBookEntryTypeEnum.INCOMING
                    ? AccountingEntryRowTypeEnum.CREDIT
                    : AccountingEntryRowTypeEnum.DEBIT,
                account_id: fItem.account_id
              };
            })
          );
        }
        entryRows.push({
          amount: sum,
          type:
            item?.type === AccountingCashBookEntryTypeEnum.INCOMING
              ? AccountingEntryRowTypeEnum.DEBIT
              : AccountingEntryRowTypeEnum.CREDIT,
          account_id: this.importData()?.active_account_id
        });

        const entry: any = {
          accounting_period_id: this.accountingPeriodService.currentPeriod()?.id,
          date_of_entry: FormlyUtil.toIsoDateString(item.date),
          title: item.title,
          description: item.reason_for_payment || item.payment_reference,
          entry_rows: {
            data: entryRows.map((fEntry: any) => ({
              ...fEntry,
              amount: Math.round(fEntry.amount * 100)
            }))
          }
        };

        // Student balances
        if (item.action?.student_balances?.length) {
          entry.student_balances = {
            data: item.action.student_balances.flatMap(studentBalance =>
              studentBalance.balances?.map(balance => ({
                month_of_balance: FormlyUtil.toIsoDateString(balance.month_of_balance),
                person_id: studentBalance.person_id?.value?.value?.person_id,
                amount: Math.round((balance.amount || 0) * 100),
                description: item.title
              }))
            )
          };
        }

        entries.push(entry);
        selectedEntries.push(item);
      });

      this.apollo
        .mutate<{
          result: {
            returning: {
              id: number;
              student_balances?: {
                id: number;
                person_id: number;
                month_of_balance: string;
              }[];
            }[];
          };
        }>({
          mutation: gql`
            mutation CreateUpdateAccountingEntries($inputs: [accounting_entry_insert_input!] = []) {
              result: insert_accounting_entry(objects: $inputs) {
                returning {
                  id
                  student_balances {
                    id
                    person_id
                    month_of_balance
                  }
                }
              }
            }
          `,
          variables: { inputs: entries }
        })
        .subscribe(mutationResult => {
          if (mutationResult.errors) {
            this.toastService.toastErrorSave();
          } else {
            mutationResult.data?.result.returning.forEach((entry, index) => {
              selectedEntries[index].is_booked = true;
              selectedEntries[index]?.action?.rows?.forEach(row => {
                row.entry_id = entry.id;
              });
              selectedEntries[index]?.action?.student_balances?.forEach(eStudentBalance => {
                // eStudentBalance.person_id
                eStudentBalance.balances?.forEach(fBalance => {
                  const balance = entry.student_balances?.find(studentBalance => {
                    return (
                      studentBalance.person_id ===
                        eStudentBalance.person_id?.value?.value?.person_id &&
                      studentBalance.month_of_balance ===
                        FormlyUtil.toIsoDateString(fBalance.month_of_balance)
                    );
                  });
                  if (balance) {
                    fBalance.student_balances_id = balance.id;
                  }
                });
              });
            });
            this.toastService.toastSuccessSave();
          }
          this.bankDataImportService.saveImport(this.importData());

          this.selections.set([]);
          this.isBusy.set(false);
        });
    });
  }

  private _setAccount(metaItem: any, item: any) {
    if (metaItem.accountIdRegExpResult.length) {
      item.action = {
        ...item.action,
        rows: metaItem.accountIdRegExpResult.map((result: any) => ({
          amount: item.amount,
          account_id: parseInt(result.groups?.['acid'] || '0')
        }))
      };
    } else if (metaItem.accountNrRegExpResult.length) {
      const accounts = this.accounts();
      const account = accounts?.find(
        account =>
          String(account.full_number) === metaItem.accountNrRegExpResult[0].groups?.['acnr']
      );
      if (account) {
        item.action = {
          ...item.action,
          rows: [
            {
              amount: item.amount,
              account_id: account.id || 0
            }
          ]
        };
      }
    }
  }

  private _parseString(regExp: RegExp, ...strings: (string | undefined)[]): RegExpExecArray[] {
    const results: RegExpExecArray[] = [];
    strings.forEach(str => {
      if (str) {
        let match;
        while ((match = regExp.exec(str)) !== null) {
          results.push(match);
        }
      }
    });
    return results;
  }
}
