import { Component, computed, effect, inject, input, ViewChild } from '@angular/core';
import { TranslocoDirective } from '@jsverse/transloco';
import { TooltipModule } from 'primeng/tooltip';
import { MenuModule } from 'primeng/menu';
import { ButtonDirective } from 'primeng/button';
import { Apollo, gql } from 'apollo-angular';
import { map } from 'rxjs';
import { NgTemplateOutlet } from '@angular/common';
import { ConfirmationService } from '~ngx-shared/layout';
import {
  AccountingCostCenterModel,
  AccountingEntryModel,
  AccountingEntryRowModel
} from '~ngx-shared/models';
import { TemplateDirective } from '~ngx-shared/directives';
import {
  DataProviderOptionModel,
  GraphQlAdvancedFilterComponent,
  GraphQlAdvancedSortComponent,
  GraphQlAdvancedTableComponent,
  GraphQlColumnModel,
  GraphQlTableModel
} from '~ngx-shared/graph-ql';

@Component({
  selector: 'app-entry-list',
  standalone: true,
  imports: [
    GraphQlAdvancedFilterComponent,
    GraphQlAdvancedSortComponent,
    GraphQlAdvancedTableComponent,
    TemplateDirective,
    TranslocoDirective,
    TooltipModule,
    MenuModule,
    ButtonDirective,
    NgTemplateOutlet
  ],
  templateUrl: './entry-list.component.html',
  styleUrl: './entry-list.component.scss'
})
export class EntryListComponent {
  readonly apollo = inject(Apollo);
  readonly confirmationService = inject(ConfirmationService);

  showFilter = input<boolean>(false);
  showSort = input<boolean>(false);
  showExport = input<boolean>(false);
  showColumnFilter = input<boolean>(true);
  showCreateButton = input<boolean>(false);

  stateKey = input<string>();
  patchOptions = input<(options: DataProviderOptionModel) => DataProviderOptionModel>();
  columns = input<GraphQlColumnModel[]>([]);
  accountPeriodId = input.required<number>();

  graphQlTable = computed(() => {
    let columns = this.columns();

    columns.unshift(...this.getDefaultColumns());

    const graphQlTable: GraphQlTableModel = {
      table: 'accounting_entry',
      isPaginated: true,

      showCurrentPageReport: true,
      columns
    };
    return graphQlTable;
  });
  @ViewChild('advancedTable') tableComponent: GraphQlAdvancedTableComponent;

  graphQlPatchOptions: (options: DataProviderOptionModel) => DataProviderOptionModel;

  constructor() {
    effect(() => {
      this.graphQlPatchOptions = options => {
        const currentPeriodId = this.accountPeriodId();
        const patchOptions = this.patchOptions();
        if (patchOptions) {
          options = patchOptions(options);
        }

        if (!options.filter) {
          options.filter = {};
        }
        if (!options.filter._and) {
          options.filter._and = [];
        }

        if (Array.isArray(options?.filter?._and)) {
          options.filter._and = options.filter._and.filter(
            (cond: any) => !cond.accounting_period_id
          );
          options.filter._and.push({
            accounting_period_id: {
              _eq: currentPeriodId
            }
          });
        }

        // Add default sorting by updated_at
        if (!options.sortBy?.length) {
          options.sortBy = [{ number: 'desc_nulls_last' }];
        }

        return options;
      };
    });
  }

  getDefaultColumns(): GraphQlColumnModel[] {
    const columns: GraphQlColumnModel[] = [
      {
        path: 'id',
        filter: { isNotFilterable: true },
        sort: { isSortable: false },
        isNotExportable: true,
        isNotSelectable: true
      },
      {
        path: 'number',
        classHeader: 'text-center',
        classBody: 'text-center',
        type: 'number',
        filter: { type: 'number' },
        sort: { isSortable: true }
      },
      {
        label: 'date',
        path: 'date_of_entry',
        type: 'date',
        filter: { type: 'date' },
        sort: { isSortable: true }
      },
      {
        path: 'title',
        filter: { type: 'string' },
        sort: { isSortable: true }
      },
      {
        path: 'amount',
        type: 'currency',
        filter: { isNotFilterable: true },
        sort: { isSortable: true }
      },
      {
        label: 'debit',
        path: 'debit',
        query: 'debits:entry_rows(where:{type: {_eq: debit }}) { type, account { id, full_name } }',
        patchResult: value => {
          return value?.debits?.map(
            (entryRow: AccountingEntryRowModel) => entryRow.account?.full_name
          );
        },
        filter: { isNotFilterable: true },
        sort: { isSortable: false }
      },
      {
        label: 'credit',
        path: 'credit',
        query:
          'credits:entry_rows(where:{type: {_eq: credit }}) { type, account { id, full_name } }',
        patchResult: value => {
          return value?.credits?.map(
            (entryRow: AccountingEntryRowModel) => entryRow.account?.full_name
          );
        },
        filter: { isNotFilterable: true },
        sort: { isSortable: false }
      },
      {
        label: 'cost_center',
        path: 'cost_center.name',
        filter: {
          label: 'cost_center',
          path: 'cost_center_id',
          type: 'array',
          options: this.apollo
            .query<{
              result: AccountingCostCenterModel[];
            }>({
              query: gql`
                query ReadAccountingCostCenters($where: accounting_cost_center_bool_exp) {
                  result: accounting_cost_center(where: $where) {
                    id
                    name
                  }
                }
              `,
              variables: {
                where: {}
              }
            })
            .pipe(
              map(queryResult =>
                queryResult.data.result.map(item => ({
                  label: item.name,
                  value: item.id
                }))
              )
            )
        },
        sort: { isSortable: false },
        hidden: true
      },
      {
        label: 'cash_book_entry',
        path: 'cash_book_entry.number',
        classHeader: 'text-center',
        classBody: 'text-center',
        type: 'number',
        filter: { type: 'number' },
        sort: { isSortable: true },
        hidden: true
      },
      {
        path: 'transaction_identifier',
        filter: { type: 'string' },
        sort: { isSortable: true },
        hidden: true
      },
      {
        path: 'external_bill_number',
        filter: { type: 'string' },
        sort: { isSortable: true },
        hidden: true
      },
      {
        path: 'description',
        filter: { type: 'string' },
        sort: { isSortable: true },
        hidden: true
      },
      {
        path: 'created_at',
        type: 'datetime',
        filter: { type: 'date' },
        sort: { isSortable: true },
        hidden: true
      },
      {
        path: 'updated_at',
        type: 'datetime',
        filter: { type: 'date' },
        sort: { isSortable: true },
        hidden: true
      }
    ];

    return columns;
  }

  getRow(data: any): AccountingEntryModel | undefined {
    return data;
  }
}
