<div [ngClass]="this.props.containerClass || 'flex'" *transloco="let transloco">
  <p-checkbox
    [binary]="this.props.binary || true"
    [ngClass]="{
      'ng-dirty ng-invalid p-invalid': this.showError
    }"
    [readonly]="this.props.readonly"
    [formControl]="this.formControl"
    [formlyAttributes]="this.field">
  </p-checkbox>
</div>
