import { Component, inject, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Apollo, gql } from 'apollo-angular';
import { ActivatedRoute } from '@angular/router';
import { AbstractControl, FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { map, of, switchMap, tap } from 'rxjs';
import { cloneDeep } from 'lodash-es';
import { TranslocoService } from '@jsverse/transloco';
import { BasePageComponent, LoadingService } from '~ngx-shared/layout';
import { FormlyModule, FormlyUtil, FormSaveModel, FormSubmitModel } from '~ngx-shared/formly';
import { AuthorizationService } from '~ngx-shared/authentication';
import { FormlyService } from '~madrasa/services';
import { AccountingPeriodService } from '~madrasa/accounting/services/accounting-period.service';
import {
  AccountingAccountCategoryEnum,
  AccountingAccountModel,
  AccountingCashBookEntryTypeEnum,
  AccountingCostCenterModel,
  AccountingEntryModel
} from '~ngx-shared/models';
import { ModelUtil } from '~ngx-shared/utils';

@UntilDestroy()
@Component({
  selector: 'app-create-entry-form-page',
  standalone: true,
  imports: [BasePageComponent, FormlyModule],
  templateUrl: './create-entry-form-page.component.html',
  styleUrl: './create-entry-form-page.component.scss'
})
export class CreateEntryFormPageComponent implements OnInit {
  readonly apollo = inject(Apollo);
  readonly activatedRoute = inject(ActivatedRoute);
  readonly loadingService = inject(LoadingService);
  readonly authorizationService = inject(AuthorizationService);
  readonly formlyService = inject(FormlyService);
  readonly translocoService = inject(TranslocoService);
  readonly accountingPeriodService = inject(AccountingPeriodService);

  form = new FormGroup({});
  model: AccountingEntryModel = {};
  options: FormlyFormOptions = {
    formState: {
      transloco: 'madrasa.forms.create_entry'
    }
  };
  fields: FormlyFieldConfig[];
  submit: FormSubmitModel;

  ngOnInit(): void {
    this.loadingService.startLoading();

    this.activatedRoute.data
      .pipe(
        untilDestroyed(this),
        switchMap(data => {
          const result = !!data?.['crud'] && !data['crud']['is_new'] && !!data['crud']['id'];
          if (result) {
            return this.apollo
              .query<{ result: AccountingEntryModel }>({
                query: gql`
                  query ReadAccountingEntryId($id: bigint!) {
                    result: accounting_entry_by_pk(id: $id) {
                      id
                      accounting_period_id
                      date_of_entry
                      title
                      transaction_identifier
                      external_bill_number
                      cost_center_id
                      description

                      cost_center {
                        name
                      }

                      receipt_documents {
                        id
                      }
                    }
                  }
                `,
                variables: {
                  id: data['crud']['id']
                }
              })
              .pipe(map(queryResult => queryResult.data?.result));
          } else {
            return of(undefined);
          }
        })
      )
      .subscribe(result => {
        let type = this.activatedRoute.snapshot.queryParamMap.get('type');

        if (type === AccountingCashBookEntryTypeEnum.INCOMING) {
          type = AccountingCashBookEntryTypeEnum.INCOMING;
        } else if (type === AccountingCashBookEntryTypeEnum.OUTGOING) {
          type = AccountingCashBookEntryTypeEnum.OUTGOING;
        } else {
          type = null;
        }

        const cash_book_entry_id = Number(
          this.activatedRoute.snapshot.queryParamMap.get('cash_book_entry_id')
        );
        const amount =
          (Number(this.activatedRoute.snapshot.queryParamMap.get('amount')) || 0) / 100;
        const date_of_entry = FormlyUtil.fromIsoDateString(
          this.activatedRoute.snapshot.queryParamMap.get('date_of_entry') as string
        );

        const school_id = Number(this.activatedRoute.snapshot.queryParamMap.get('school_id'));

        let model: any | undefined = {
          debit: [
            {
              type: 'debit'
            }
          ],
          credit: [
            {
              type: 'credit'
            }
          ]
        };

        if (!isNaN(amount) && amount > 0) {
          model = {
            ...model,
            debit: [
              {
                ...model.debit[0],
                amount
              }
            ],
            credit: [
              {
                ...model.credit[0],
                amount
              }
            ]
          };
        }

        if (!isNaN(cash_book_entry_id) && cash_book_entry_id > 0) {
          model = {
            ...model,
            cash_book_entry_id
          };

          this.form.markAsDirty();
          this.form.markAllAsTouched();
        }

        if (date_of_entry && !isNaN(date_of_entry.getTime())) {
          model = {
            ...model,
            date_of_entry
          };
        }

        if (result) {
          model = cloneDeep(result);

          if (model) {
            this.model = {
              ...model
            };
          }
        }

        this.model = model;

        function calcBalance(
          control: AbstractControl,
          result: (debitsSum: number, creditSum: number) => boolean
        ): boolean {
          let debitsSum = 0;
          let creditSum = 0;
          const debit = control.value?.debit;
          const credit = control.value?.credit;

          if (debit && Array.isArray(debit)) {
            debitsSum = debit.reduce(
              (n: number, k: AccountingEntryModel) => n + Math.round((k.amount || 0) * 100) / 100,
              0
            );
          }
          if (credit && Array.isArray(credit)) {
            creditSum = credit.reduce(
              (n: number, k: AccountingEntryModel) => n + Math.round((k.amount || 0) * 100) / 100,
              0
            );
          }
          return result(Math.round(debitsSum * 100) / 100, Math.round(creditSum * 100) / 100);
        }

        this.fields = [
          {
            validators: {
              checkDebit: {
                expression: (control: AbstractControl) =>
                  calcBalance(
                    control,
                    (debitsSum, creditSum) => debitsSum == creditSum || debitsSum < creditSum
                  ),
                message: () =>
                  this.translocoService.selectTranslate(
                    'madrasa.forms.create_entry.debit_is_larger'
                  ),
                errorPath: 'debit'
              },

              checkCredit: {
                expression: (control: AbstractControl) =>
                  calcBalance(
                    control,
                    (debitsSum, creditSum) => debitsSum == creditSum || debitsSum > creditSum
                  ),
                message: () =>
                  this.translocoService.selectTranslate(
                    'madrasa.forms.create_entry.credit_is_larger'
                  ),
                errorPath: 'credit'
              }
            },
            fieldGroup: [
              {
                key: 'id'
              },
              {
                key: 'cash_book_entry_id'
              },
              {
                fieldGroupClassName: 'flex flex-col md:grid md:grid-cols-2 md:gap-4',
                fieldGroup: [
                  this.createEntryRow(
                    'debit',
                    type
                      ? {
                          category: {
                            _eq:
                              type === AccountingCashBookEntryTypeEnum.INCOMING
                                ? AccountingAccountCategoryEnum.ACTIVE
                                : type === AccountingCashBookEntryTypeEnum.OUTGOING
                                  ? AccountingAccountCategoryEnum.EXPENSE
                                  : ''
                          }
                        }
                      : undefined,
                    school_id
                  ),
                  this.createEntryRow(
                    'credit',
                    type
                      ? {
                          category: {
                            _eq:
                              type === AccountingCashBookEntryTypeEnum.INCOMING
                                ? AccountingAccountCategoryEnum.INCOME
                                : type === AccountingCashBookEntryTypeEnum.OUTGOING
                                  ? AccountingAccountCategoryEnum.ACTIVE
                                  : ''
                          }
                        }
                      : undefined,
                    school_id
                  )
                ]
              },
              FormlyUtil.createRow([
                FormlyUtil.createDatePickerField('date_of_entry', {
                  props: {
                    label: 'date',
                    required: true
                  }
                }),
                FormlyUtil.createTextField('title')
              ]),
              FormlyUtil.createRow([
                FormlyUtil.createTextField('external_bill_number'),
                FormlyUtil.createTextField('transaction_identifier')
              ]),
              FormlyUtil.createRow([
                FormlyUtil.createSelectField('cost_center_id', {
                  props: {
                    label: 'cost_center',
                    options: this.apollo
                      .query<{
                        result: AccountingCostCenterModel[];
                      }>({
                        query: gql`
                          query ReadAccountingCostCenters(
                            $where: accounting_cost_center_bool_exp = {}
                          ) {
                            result: accounting_cost_center(order_by: { name: asc }, where: $where) {
                              id
                              name
                            }
                          }
                        `,
                        variables: {
                          where: {
                            accounting_period_id: {
                              _eq: this.accountingPeriodService.currentPeriod()?.id
                            }
                          }
                        }
                      })
                      .pipe(
                        map(queryResult =>
                          queryResult.data?.result?.map(item => ({
                            label: item.name,
                            value: item.id
                          }))
                        )
                      )
                  }
                })
              ]),
              FormlyUtil.createRow([
                this.formlyService.createFileUploadFieldConfig('receipt_documents', {
                  props: {
                    label: 'receipts',
                    namespace: 'receipt_document',
                    multiple: true
                  }
                })
              ]),
              FormlyUtil.createRow([FormlyUtil.createTextAreaField('description')])
            ]
          }
        ];

        this.loadingService.stopLoading();
      });

    this.submit = (formSaveModel: FormSaveModel) => {
      const input = {
        ...formSaveModel.input,
        accounting_period_id: this.accountingPeriodService.currentPeriod()?.id,
        date_of_entry: FormlyUtil.toIsoDateString(formSaveModel.input.date_of_entry),
        entry_rows: {
          data: [...formSaveModel.input.debit, ...formSaveModel.input.credit].map((item: any) => ({
            ...item,
            account_id: item.account_id,
            amount: Math.round(item.amount * 100)
          }))
        },
        receipt_documents: {
          data:
            formSaveModel.input.receipt_documents?.map((id: any) => ({
              id
            })) || []
        }
      };

      ModelUtil.deleteKey(input, 'debit');
      ModelUtil.deleteKey(input, 'credit');

      return this.apollo.mutate({
        mutation: gql`
          mutation CreateUpdateAccountingEntry($input: accounting_entry_insert_input!) {
            result: insert_accounting_entry_one(object: $input) {
              __typename
            }
          }
        `,
        variables: { input }
      });
    };

    this.accountingPeriodService.getPeriods().subscribe();
  }

  createEntryRow(type: string, category?: any, school_id?: any): FormlyFieldConfig {
    return FormlyUtil.createRepeat(
      {
        key: type,
        props: {
          label: type,
          required: true,
          initialCount: 1,
          addTextValue: type
        }
      },
      [
        {
          key: 'type',
          defaultValue: type
        },
        FormlyUtil.createNumberField('amount', {
          props: {
            required: true,
            maxFractionDigits: 2,
            iconPre: 'pi pi-euro',
            min: 0
          }
        }),
        FormlyUtil.createSelectField('account_id', {
          props: {
            label: 'account',
            required: true,
            showClear: true,
            canFilter: true,
            options: []
          },
          hooks: {
            onInit: (field: FormlyFieldConfig) => {
              return this.apollo
                .query<{
                  result: AccountingAccountModel[];
                }>({
                  query: gql`
                    query ReadAccountingAccounts($where: accounting_account_bool_exp = {}) {
                      result: accounting_account(
                        where: $where
                        order_by: [
                          { account_group: { number: asc } }
                          { number: asc }
                          { name: asc }
                        ]
                      ) {
                        id
                        show_always
                        full_name
                      }
                    }
                  `,
                  variables: {
                    where: {
                      _or: [
                        {
                          account_group: {
                            accounting_period_id: {
                              _eq: this.accountingPeriodService.currentPeriod()?.id
                            },
                            ...(category ? category : {})
                          },
                          ...(school_id ? { school_id: { _eq: school_id } } : {})
                        },
                        { show_always: { _eq: true } }
                      ]
                    }
                  }
                })
                .pipe(
                  tap(queryResult => {
                    const options = queryResult.data?.result?.map(item => ({
                      label: item.full_name,
                      value: item.id
                    }));

                    if (field.props) {
                      field.props.options = options;

                      field.formControl?.setValue(
                        queryResult.data?.result.find(item => !item.show_always)?.id
                      );
                    }
                  })
                );
            }
          }
        })
      ]
    );
  }
}
