{
  "name": "madrasa",
  "version": "0.4.58",
  "scripts": {
    "ng": "ng",
    "start": "ng serve --port 4210",
    "build": "ng build",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "lint": "ng lint"
  },
  "private": true,
  "dependencies": {
    "@angular-architects/module-federation": "^18.0.6",
    "@angular/animations": "^18.2.12",
    "@angular/cdk": "^18.2.13",
    "@angular/common": "^18.2.12",
    "@angular/compiler": "^18.2.12",
    "@angular/core": "^18.2.12",
    "@angular/forms": "^18.2.12",
    "@angular/platform-browser": "^18.2.12",
    "@angular/platform-browser-dynamic": "^18.2.12",
    "@angular/router": "^18.2.12",
    "@angular/service-worker": "^18.2.12",
    "@apollo/client": "^3.11.10",
    "@jsverse/transloco": "^7.5.0",
    "@ngneat/effects": "^2.1.2",
    "@ngneat/effects-ng": "^3.1.4",
    "@ngneat/elf": "^2.5.1",
    "@ngneat/elf-cli-ng": "^1.0.0",
    "@ngneat/elf-devtools": "^1.3.0",
    "@ngneat/elf-entities": "^5.0.2",
    "@ngneat/elf-pagination": "^1.1.0",
    "@ngneat/elf-persist-state": "^1.2.1",
    "@ngneat/elf-requests": "^1.9.2",
    "@ngneat/elf-state-history": "^1.4.0",
    "@ngneat/until-destroy": "^10.0.0",
    "@ngx-formly/core": "^6.3.10",
    "apollo-angular": "^7.2.1",
    "chardet": "^2.0.0",
    "date-fns": "^4.1.0",
    "date-fns-tz": "^3.2.0",
    "graphql": "^16.9.0",
    "graphql-ws": "^5.16.0",
    "keycloak-angular": "^16.1.0",
    "lodash-es": "^4.17.21",
    "ngx-filesize": "^3.0.4",
    "ngx-iban-validator": "^1.2.2",
    "ngx-permissions": "^17.1.0",
    "ngx-quill": "^26.0.10",
    "primeicons": "^7.0.0",
    "primeng": "^17.18.12",
    "rxjs": "~7.8.1",
    "tslib": "^2.8.1",
    "uuid": "^11.0.3",
    "xlsx": "https://cdn.sheetjs.com/xlsx-0.20.1/xlsx-0.20.1.tgz",
    "zone.js": "~0.14.10"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.12",
    "@angular-eslint/builder": "18.4.0",
    "@angular-eslint/eslint-plugin": "18.4.0",
    "@angular-eslint/eslint-plugin-template": "18.4.0",
    "@angular-eslint/schematics": "18.4.0",
    "@angular-eslint/template-parser": "18.4.0",
    "@angular/cli": "^18.2.12",
    "@angular/compiler-cli": "^18.2.12",
    "@types/jasmine": "~5.1.4",
    "@types/lodash-es": "^4.17.12",
    "@typescript-eslint/eslint-plugin": "7.17.0",
    "@typescript-eslint/parser": "7.17.0",
    "autoprefixer": "^10.4.20",
    "eslint": "8.57.0",
    "eslint-config-prettier": "^9.1.0",
    "eslint-import-resolver-typescript": "^3.6.3",
    "eslint-plugin-import": "^2.31.0",
    "eslint-plugin-prettier": "^5.2.1",
    "jasmine-core": "~5.4.0",
    "karma": "~6.4.4",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.1",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "ng-packagr": "^18.2.1",
    "postcss": "^8.4.49",
    "prettier": "^3.3.3",
    "prettier-plugin-tailwindcss": "^0.6.9",
    "tailwindcss": "^3.4.15",
    "typescript": "~5.5.3"
  }
}
