<div *transloco="let transloco" class="space-y-3">
  @if (this.columns && this.columns.length) {
    <span class="block text-2xl">{{ transloco('sort_by') }}</span>
    @for (sort of this.sorts; track sort; let index = $index; let last = $last) {
      <div class="flex items-center gap-3">
        <div class="flex w-full flex-col items-center gap-3 md:flex-row">
          <div class="w-full">
            <p-dropdown
              (onChange)="this.updateState(sort)"
              [(ngModel)]="sort.column"
              [filter]="true"
              [options]="this.columns"
              [placeholder]="'&nbsp;'"
              appendTo="body"
              class="p-fluid">
              <ng-template let-item pTemplate="item">
                <span>{{ transloco(item.label ?? item.path) }}</span>
              </ng-template>
              <ng-template let-selectedItem pTemplate="selectedItem">
                <span>{{ transloco(selectedItem.label ?? selectedItem.path) }}</span>
              </ng-template>
            </p-dropdown>
          </div>
          <div class="w-full">
            <p-dropdown
              (onChange)="this.updateState()"
              [(ngModel)]="sort.sortBy"
              [placeholder]="'&nbsp;'"
              [options]="this.sortOptions"
              appendTo="body"
              class="p-fluid"
              optionLabel="label"
              optionValue="value">
              <ng-template let-item pTemplate="item">
                <span>{{ transloco(item['label'] || '') }}</span>
              </ng-template>
              <ng-template let-selectedItem pTemplate="selectedItem">
                <span>{{ transloco(selectedItem['label'] || '') }}</span>
              </ng-template>
            </p-dropdown>
          </div>
        </div>
        <div class="w-fit">
          <button (click)="this.removeSortBy(index)" pButton type="button">
            <i class="pi pi-times"></i>
          </button>
        </div>
      </div>

      @if (!last) {
        <hr class="block h-0.5 bg-black md:hidden" />
      }
    }
    <div>
      <button
        (click)="this.addSortBy()"
        [label]="transloco('add')"
        class="p-button-success"
        icon="pi pi-plus"
        pButton
        type="button"></button>
    </div>
  }
</div>
