import { Component, Type } from '@angular/core';
import { FieldType, FieldTypeConfig, FormlyFieldConfig } from '@ngx-formly/core';
import { FieldWrapperProps } from '../field-wrapper/field-wrapper.component';

interface CheckboxProps extends FieldWrapperProps {
  text?: boolean;
  binary?: boolean;

  containerClass?: string;
}

export interface CheckboxFieldConfig extends FormlyFieldConfig<CheckboxProps> {
  type?: 'checkbox' | Type<CheckboxTypeComponent>;
}

@Component({
  selector: 'lib-formly-checkbox-type',
  templateUrl: './checkbox-type.component.html',
  styleUrl: './checkbox-type.component.css'
})
export class CheckboxTypeComponent extends FieldType<FieldTypeConfig<CheckboxProps>> {}
