<lib-layout-base-page
  *transloco="let transloco"
  [titleAddon]="this.accountingPeriodService.currentPeriod()?.id"
  [title]="this.importData()?.name">
  <div class="m-base flex flex-col space-y-5">
    <p-table
      (onLazyLoad)="this.loadImportData($event)"
      (selectionChange)="this.selectionChange($event)"
      *transloco="let transloco"
      [alwaysShowPaginator]="(this.importData()?.items?.length || 0) > 0"
      [lazy]="true"
      [loading]="this.isLoading()"
      [paginator]="true"
      [rowsPerPageOptions]="[10, 25, 50]"
      [rows]="10"
      [showCurrentPageReport]="(this.importData()?.items?.length || 0) > 0"
      [totalRecords]="this.importData()?.items?.length || 0"
      [value]="this.slicedImportDataItems()"
      currentPageReportTemplate="{{ transloco('components.graph_ql_table.table_report_template') }}"
      responsiveLayout="scroll"
      styleClass="p-datatable-gridlines p-datatable-striped md:shadow-none shadow">
      <ng-template pTemplate="caption">
        <div class="flex justify-between">
          <div class="p-2">
            <h2>{{ transloco('entries') }}</h2>
          </div>
          <div>
            <button
              (click)="this.book($event)"
              [disabled]="!this.selections().length || this.isBusy()"
              [label]="transloco('to_book')"
              pButton
              type="button"></button>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th class="w-16 text-center">
            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
          </th>
          <th class="w-8 text-center">{{ transloco('number') }}</th>
          <th class="w-16">{{ transloco('mandate') }}</th>
          <th class="w-56">{{ transloco('title') }}</th>
          <th class="w-96">{{ transloco('actions') }}</th>
          <th class="w-16 text-center">{{ transloco('date') }}</th>
          <th class="w-16 text-center">{{ transloco('amount') }}</th>
          <th class="w-56">
            {{ transloco('reason_for_payment') }} / {{ transloco('payment_reference') }}
          </th>
        </tr>
      </ng-template>

      <ng-template let-data let-rowIndex="rowIndex" pTemplate="body">
        <tr>
          <td class="text-center">
            @if (this.getRow(data).is_booked) {
              <span class="pi pi-check-circle text-primary"></span>
            } @else {
              @if (this.getRowAction(data)?.rows?.length) {
                <p-tableCheckbox [disabled]="this.isBusy()" [value]="data"></p-tableCheckbox>
              }
            }
          </td>
          <td class="text-center">{{ rowIndex + 1 }}</td>
          <td>
            <span>{{ this.getRow(data).mandate }}</span>
          </td>
          <td class="">
            <div class="flex flex-col">
              <input
                [(ngModel)]="this.getRow(data).title"
                [disabled]="!!this.getRow(data).is_booked"
                pInputText
                type="text" />
            </div>
          </td>
          <td>
            <div class="flex items-center justify-between">
              <div class="flex flex-col">
                @for (row of this.getRowAction(data)?.rows; track row) {
                  <div>
                    @if (row.entry_id) {
                      <a
                        [routerLink]="['/', 'accounting', 'entries', 'detail', row.entry_id]"
                        class="cursor-pointer hover:text-primary hover:underline">
                        <span class="">{{ this.getAccountFullName(row.account_id) }}</span>
                        :
                        <span class="">{{ row.amount | currency }}</span>
                      </a>
                    } @else {
                      <span class="">{{ this.getAccountFullName(row.account_id) }}</span>
                      :
                      <span class="">{{ row.amount | currency }}</span>
                    }
                  </div>
                }
                @if (this.getRowAction(data)?.student_balances?.length) {
                  <hr />
                }
                @for (
                  studentBalance of this.getRowAction(data)?.student_balances;
                  track studentBalance;
                  let last = $last
                ) {
                  <div>
                    <span class="">{{ studentBalance.person_id?.value?.value?.full_name }}</span>

                    @for (balance of studentBalance.balances; track balance) {
                      <div>
                        @if (balance.student_balances_id) {
                          <a
                            [routerLink]="[
                              '/',
                              'accounting',
                              'student-balances',
                              'detail',
                              balance.student_balances_id
                            ]"
                            class="cursor-pointer hover:text-primary hover:underline">
                            <span class="">{{
                              balance.month_of_balance | libDate: 'MM/yyyy'
                            }}</span>
                            :
                            <span class="">{{ balance.amount | currency }}</span>
                          </a>
                        } @else {
                          <span class="">{{ balance.month_of_balance | libDate: 'MM/yyyy' }}</span>
                          :
                          <span class="">{{ balance.amount | currency }}</span>
                        }
                      </div>
                    }
                  </div>

                  @if (!last) {
                    <hr />
                  }
                }
              </div>

              @if (!this.getRow(data).is_booked) {
                <div class="flex">
                  <button
                    (click)="this.editAction(data, rowIndex)"
                    [pTooltip]="transloco('edit')"
                    class="p-button-warning p-button-text"
                    icon="pi pi-pencil"
                    pButton
                    tooltipPosition="top"
                    type="button"></button>
                  @if (this.getRowAction(data)?.rows?.length) {
                    <button
                      (click)="this.deleteAction(data, rowIndex)"
                      [pTooltip]="transloco('delete')"
                      class="p-button-danger p-button-text"
                      icon="pi pi-trash"
                      pButton
                      tooltipPosition="top"
                      type="button"></button>
                  }
                </div>
              }
            </div>
          </td>
          <td class="text-center">
            <span>{{ this.getRow(data).date | libDate }}</span>
          </td>
          <td class="text-center">
            <span
              [ngClass]="{
                'text-green-500':
                  this.getRow(data).type === AccountingCashBookEntryTypeEnum.INCOMING,
                'text-red-500': this.getRow(data).type === AccountingCashBookEntryTypeEnum.OUTGOING
              }"
              class="font-bold"
              >{{ this.getRow(data).amount | currency }}</span
            >
          </td>
          <td>
            @if (this.getRow(data).reason_for_payment) {
              <span>{{ this.getRow(data).reason_for_payment }}</span>
            }
            @if (this.getRow(data).payment_reference) {
              @if (this.getRow(data).reason_for_payment) {
                /
              }
              <span>{{ this.getRow(data).payment_reference }}</span>
            }
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td [attr.colspan]="7" class="text-center">
            <span>{{ transloco('no_data') }}</span>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</lib-layout-base-page>
