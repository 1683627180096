import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, InjectionToken, ModuleWithProviders, NgModule } from '@angular/core';
import { KeycloakAngularModule, KeycloakEventType, KeycloakService } from 'keycloak-angular';
import { NgxPermissionsModule } from 'ngx-permissions';
import { environment } from '~ngx-shared/environment';
import { ClaimInterceptor, GlobalInterceptor, RoleInterceptor } from './interceptors';
import { AuthenticationConfig } from './models';
import { AuthenticationService, AuthorizationService } from './services';

export const AUTH_CONFIG_TOKEN = new InjectionToken<AuthenticationConfig>('AUTH_CONFIG_TOKEN');

@NgModule({
  declarations: [],
  imports: [CommonModule, NgxPermissionsModule.forRoot(), KeycloakAngularModule],
  exports: []
})
export class AuthenticationModule {
  static forRoot(config: AuthenticationConfig): ModuleWithProviders<AuthenticationModule> {
    return {
      ngModule: AuthenticationModule,
      providers: [
        {
          provide: APP_INITIALIZER,
          useFactory:
            (keycloak: KeycloakService, authenticationService: AuthenticationService) => () => {
              return keycloak
                .init({
                  config: {
                    url: environment.issuer,
                    realm: 'madrasa',
                    clientId: config.clientId
                  },
                  initOptions: {
                    onLoad: 'login-required',
                    pkceMethod: 'S256',
                    silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html'
                  },
                  shouldAddToken: request => {
                    return request.url.includes('graphql') || request.url.includes('api');
                  }
                })
                .then(() => {
                  keycloak.keycloakEvents$.subscribe({
                    next(event) {
                      if (event.type == KeycloakEventType.OnTokenExpired) {
                        void keycloak.updateToken(60);
                      }
                    }
                  });
                })
                .then(() => {
                  authenticationService.init();
                });
            },
          deps: [KeycloakService, AuthenticationService],
          multi: true
        },
        { provide: AUTH_CONFIG_TOKEN, useValue: config },
        { provide: HTTP_INTERCEPTORS, useClass: GlobalInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: RoleInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ClaimInterceptor, multi: true },
        AuthenticationService,
        AuthorizationService
      ]
    };
  }
}
