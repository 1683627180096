import { Component, computed, effect, inject, OnInit, signal } from '@angular/core';
import { TranslocoDirective } from '@jsverse/transloco';
import { ButtonDirective } from 'primeng/button';
import { NgxPermissionsModule } from 'ngx-permissions';
import { Router, RouterLink } from '@angular/router';
import { CurrencyPipe, NgClass } from '@angular/common';
import { Apollo, gql } from 'apollo-angular';
import { AccountingPeriodService } from '~madrasa/accounting/services/accounting-period.service';
import { BasePageComponent, BusyComponent } from '~ngx-shared/layout';
import { AccountingCashBookEntryCategoryPermission, Aggregate } from '~ngx-shared/models';
import { CashBookEntryListComponent } from '~madrasa/accounting/components/cash-book-entry-list/cash-book-entry-list.component';

@Component({
  selector: 'app-cash-book-entry-list-page',
  standalone: true,
  imports: [
    BasePageComponent,
    TranslocoDirective,
    ButtonDirective,
    NgxPermissionsModule,
    RouterLink,
    CashBookEntryListComponent,
    BusyComponent,
    CurrencyPipe,
    NgClass
  ],
  templateUrl: './cash-book-entry-list-page.component.html',
  styleUrl: './cash-book-entry-list-page.component.scss'
})
export class CashBookEntryListPageComponent implements OnInit {
  readonly apollo = inject(Apollo);
  readonly router = inject(Router);
  readonly accountingPeriodService = inject(AccountingPeriodService);

  readonly incoming = signal(0);
  readonly outgoing = signal(0);

  readonly balance = computed(() => this.incoming() - this.outgoing());

  protected readonly AccountingCashBookCategoryPermission =
    AccountingCashBookEntryCategoryPermission;

  constructor() {
    effect(() => {
      const currentPeriod = this.accountingPeriodService.currentPeriod();
      if (currentPeriod?.id) {
        this.apollo
          .query<{
            incoming: Aggregate;
            outgoing: Aggregate;
            active: Aggregate;
          }>({
            query: gql`
              query GetCashBookEntrySum($accountingPeriodId: bigint!) {
                incoming: accounting_cash_book_entry_aggregate(
                  where: {
                    type: { _eq: incoming }
                    accounting_period_id: { _eq: $accountingPeriodId }
                  }
                ) {
                  aggregate {
                    sum {
                      amount
                    }
                  }
                }
                outgoing: accounting_cash_book_entry_aggregate(
                  where: {
                    type: { _eq: outgoing }
                    accounting_period_id: { _eq: $accountingPeriodId }
                  }
                ) {
                  aggregate {
                    sum {
                      amount
                    }
                  }
                }
                active: accounting_account_aggregate(
                  where: {
                    _and: [
                      { number: { _gte: 700 } }
                      { number: { _lte: 799 } }
                      {
                        account_group: {
                          number: { _eq: 2 }
                          accounting_period_id: { _eq: $accountingPeriodId }
                        }
                      }
                    ]
                  }
                ) {
                  aggregate {
                    sum {
                      opening_amount
                    }
                  }
                }
              }
            `,
            variables: {
              accountingPeriodId: currentPeriod.id
            }
          })
          .subscribe(queryResult => {
            this.incoming.set(
              (queryResult.data?.incoming?.aggregate?.sum?.['amount'] || 0) / 100 +
                (queryResult.data?.active?.aggregate?.sum?.['opening_amount'] || 0) / 100
            );
            this.outgoing.set((queryResult.data?.outgoing?.aggregate?.sum?.['amount'] || 0) / 100);
          });
      }
    });
  }
  ngOnInit() {
    this.accountingPeriodService.getPeriods().subscribe();
  }
}
